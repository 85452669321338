// Import libraries
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Img from 'gatsby-image';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledBanner = styled.div`
    background-image: url(${props => !!props.image.childImageSharp ? props.image.childImageSharp.fluid.src : props.image});
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;

    & .overlay {
        display: grid;
        grid-template-rows: 1fr 6rem;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .7);
        align-items: center;
        justify-content: center;

        & .content {
            display: grid;
            grid-template-rows: repeat(2, min-content);
            grid-row-gap: 2rem;
            max-width: 1100px;
            margin-top: 100px; /* Equal to header height */
            padding: 0 var(--padding-right) 0 var(--padding-left);
            color: #fff;

            & .primary-color {
                color: var(--color-primary);
            }

            & h1 {
                font-size: var(--font-size-banner-h1);
                font-weight: var(--font-weight-banner-h1);
                line-height: var(--line-height-banner-h1);
            }

            & p {
                font-size: 3rem;
                font-weight: 400;
                line-height: 1.5;
            }

            &-link {
                justify-self: start;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                background-color: var(--color-primary);
                border: 2px solid var(--color-primary);
                border-radius: 0;
                font-size: 2rem;
                padding: 1rem 2rem;
                color: #fff;
                font-weight: 400;

                & span {
                    margin-right: 10px;
                }
            }

            &-link:link,
            &-link:visited {
                color: currentColor;
                text-decoration: none;
                outline: 0;
                transition: color .25s ease;
            }

            &-link:hover,
            &-link:focus {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(-3px);
                box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
            }

            &-link:active {
                text-decoration: none;
                color: currentColor;
                outline: 0;
                transform: translateY(0);
                box-shadow: none;
            }
        }

        & .scroll-down-button {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            margin-left: var(--padding-left);
            font-size: 2.5rem;
            background-color: #fff;
            color: #000;
            cursor: pointer;
            transition: background-color .25s ease-in-out,
                        color .25s ease-in-out;
            
            &:hover {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
`;

const StyledAboutUs = styled.div`
    & .row {
        display: grid;

        &--1 {
            grid-template-columns: 66.66% 33.33%;

            @media only screen and (max-width: 800px) {
                grid-template-columns: 1fr;
            }

            & > .container.content {
                background-color: var(--color-background-2);
            }
        }

        &--2 {
            grid-template-columns: 33.33% 66.66%;

            @media only screen and (max-width: 800px) {
                grid-template-columns: 1fr;

                & .container.image {
                    grid-row: 2 / 3;
                }
            }
        }

        &--3 {
            grid-template-columns: repeat(3, 33.33%);

            @media only screen and (max-width: 1300px) {
                grid-template-columns: repeat(2, 1fr);
            }

            & .container.content {
                background-color: var(--color-background-2);
                text-align: center;

                @media only screen and (max-width: 1300px) {
                    grid-row: 1 / 2;
                    grid-column: 1 / -1;
                }
            }
        }
    }

    & .container.content {
        display: grid;
        align-items: center;

        & .content {
            display: grid;
            grid-template-rows: repeat(2, max-content);
            align-content: center;
            grid-row-gap: 2rem;
            padding: var(--padding-right) var(--padding-right) var(--padding-left) var(--padding-left);

            & h1 {
                font-size: var(--font-size-body-h1);
                font-weight: var(--font-weight-body-h1);
                line-height: var(--line-height-body-h1);
            }

            & p {
                font-size: var(--font-size-body-p);
                font-weight: var(--font-weight-body-p);
                line-height: var(--line-height-body-p);
            }
        }
    }

    & .container.image {
        & .gatsby-image-wrapper {
            height: 100%;
        }
    }
`;


// Create component
const AboutUsPage = () => {
    const data = useStaticQuery(graphql`
        query {
            bannerImage: file(relativePath: { eq: "banner/over-ons/banner.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            communicatieImage: file(relativePath: { eq: "over-ons/Communicatie.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            langdurigeVerhoudingImage: file(relativePath: { eq: "over-ons/Langdurige-verhouding.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            teamMemberRoy: file(relativePath: { eq: "over-ons/roy.png" }) {
                childImageSharp {
                    fixed(width: 226, height: 226) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }

            teamMemberMike: file(relativePath: { eq: "over-ons/roy.png" }) {
                childImageSharp {
                    fixed(width: 226, height: 226) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);


    // Handle scrolling down to below banner
    const handleScrollDown = () => {
        // Get banner height
        const bannerHeight = document.querySelector('.banner').offsetHeight;
        
        // Scroll to place underneath banner
        window.scrollTo({
            top: bannerHeight,
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Over Ons" />
            <Layout withBanner={true}>
                <StyledBanner
                    className="banner"
                    image={data.bannerImage}
                >
                    <div className="overlay">
                        <div className="content">
                            <h1>Who are we?</h1>
                            <p>Our passion is to see your business grow by broadening your digital presence and discovering new opportunities in this digital age. We focus on building long-term relationships with our customers and learn all the ins and outs of your company to understand your requirements. With <span className="primary-color">boumedia</span> you take on a partner who works with you to grow your digital presence.</p>
                        </div>

                        <div className="scroll-down-button" onClick={handleScrollDown}>
                            <FontAwesomeIcon icon={faArrowDown} className="icon" />
                        </div>
                    </div>
                </StyledBanner>
                
                <StyledAboutUs>
                    <div className="row row--1">
                        <div className="container content">
                            <div className="content">
                                <h1>Communication Is Key</h1>
                                <p> Personal contact is our top priority. We immerse ourselves fully in your company so that we can advise you in the best way. Because all our products are tailor-made, we therefore believe communication is essential. You can therefore always reach us for questions or ideas so that we can provide you with the best possible service.</p>
                            </div>
                        </div>

                        <div className="container image">
                            <Img fluid={data.communicatieImage.childImageSharp.fluid} alt="" className="" />
                        </div>
                    </div>

                    <div className="row row--2">
                        <div className="container image">
                            <Img fluid={data.langdurigeVerhoudingImage.childImageSharp.fluid} alt="" className="" />
                        </div>

                        <div className="container content">
                            <div className="content">
                                <h1>Long-Lasting Relationships</h1>
                                <p>We build long-lasting relationships with our customers in orer to adapt to any changes within your business and therefore adapt our services to your ever changing requirements. We always try to improve your digital presence, especially when you are evolving.</p>
                            </div>
                        </div>
                    </div>
                </StyledAboutUs>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default AboutUsPage;